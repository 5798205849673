import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export const HEADER_HEIGHT = 4

const OuterWrapper = styled.header`
  height: ${HEADER_HEIGHT}rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--brand);
  color: var(--brand-text);
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  z-index: 1;
`

const InnerWrapper = styled.div`
  margin: auto;
  max-width: 60rem;
  padding: 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Heading = styled.h2`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin: 0;
  font-size: 1.2rem;

  &:hover {
    background-color: var(--brand-overlay);
  }
`

const WrappingLink = styled(Link)`
  text-decoration: none;
`

const Nav = styled.nav`
  font-size: 1.2rem;
`
const NavLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const Header = () => (
  <OuterWrapper>
    <InnerWrapper>
      <WrappingLink to="/">
        <Heading>Tom Andrews</Heading>
      </WrappingLink>
      <Nav>
        <NavLink to="/blog">blog</NavLink>
        <NavLink to="/projects">projects</NavLink>
      </Nav>
    </InnerWrapper>
  </OuterWrapper>
)

export default Header
