import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import Helmet from "react-helmet"

import Header, { HEADER_HEIGHT } from "./Header"
import Socials from "./Socials"

const FOOTER_HEIGHT = "6rem"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
  }

  :root {
    --emphasized-text: #161616;
    --main-text: #161616;
    --tertiary-text: #595959;
    --background: #fafafa;
    --quote-background: #eee;
    --brand: #c93380;
    --brand-text: #fafafa;
  }

  @media(prefers-color-scheme: dark) {
    :root {
      --emphasized-text: #fafafa;
      --main-text: #d1d1d1;
      --background: #111;
      --quote-background: #444;
    }
  }

  body {
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    color: var(--main-text);
    background-color: var(--background);
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: inherit;
  }

  article section {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    li > p {
      margin: 0;
    }
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  blockquote {
    background: var(--quote-background);
    padding: 0.1rem 0 0.1rem 2rem;
    margin: 0;
    font-style: italic;
    border-left: 1px solid;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: auto auto;
  width: 100%;
  max-width: 60rem;
  padding: 1.5rem;
  flex-direction: column;
  min-height: calc(100vh - ${FOOTER_HEIGHT});
  overflow: hidden;

  @supports (padding: max(0px)) {
    padding-left: max(env(safe-area-inset-left), 1.5rem);
    padding-right: max(env(safe-area-inset-right), 1.5rem);
  }
`

const Main = styled.main`
  flex: 1;
  margin-top: ${({ excludeHeader = false }) =>
    excludeHeader ? `0` : HEADER_HEIGHT - 2 + "rem"};
`

const Footer = styled.footer`
  font-size: 0.9rem;
  color: var(--brand-text);
  width: 100%;
  text-align: center;
  background-color: var(--brand);
  padding: 1rem 0;
  height: ${FOOTER_HEIGHT};
  display: grid;
  align-items: center;
`

const Layout = ({ excludeHeader = false, children }) => {
  const header = excludeHeader ? null : <Header />

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content: "initial-scale=1, viewport-fit=cover",
          },
        ]}
      />
      <GlobalStyle />
      <Wrapper>
        {header}
        <Main excludeHeader={excludeHeader}>{children}</Main>
      </Wrapper>
      <Footer>
        <Socials />
        <div>© Tom Andrews & TG ANDREWS LIMITED 2008-{new Date().getFullYear()}</div>
      </Footer>
    </>
  )
}

export default Layout
