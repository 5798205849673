import React from "react"
import styled from "styled-components"

import ExternalLink from "./ExternalLink"

import GithubIcon from "../../content/assets/github.svg"
import TwitterIcon from "../../content/assets/twitter.svg"
import YoutubeIcon from "../../content/assets/youtube.svg"
import LinkedInIcon from "../../content/assets/linkedin.svg"
import VisuallyHidden from "./VisuallyHidden"

const SiteWrapper = styled.span`
  color: white;
  font-size: 1rem;
  margin-right: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover svg {
    color: ${({ color }) => color};
  }
`

const Site = ({ color, url, children }) => (
  <SiteWrapper color={color}>
    <ExternalLink href={url}>{children}</ExternalLink>
  </SiteWrapper>
)

const Wrapper = styled.div``

const Socials = () => (
  <Wrapper>
    <Site url="https://github.com/tgandrews" color="#161616">
      <GithubIcon />
      <VisuallyHidden>Github account</VisuallyHidden>
    </Site>
    <Site url="https://twitter.com/tgandrews" color="#00acee">
      <TwitterIcon />
      <VisuallyHidden>Twitter account</VisuallyHidden>
    </Site>
    <Site url="https://www.youtube.com/user/Cahuix" color="#ff0000">
      <YoutubeIcon />
      <VisuallyHidden>YouTube account</VisuallyHidden>
    </Site>
    <Site url="https://uk.linkedin.com/in/tgandrews" color="#0073b1">
      <LinkedInIcon />
      <VisuallyHidden>LinkedIn account</VisuallyHidden>
    </Site>
  </Wrapper>
)

export default Socials
