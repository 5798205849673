import React from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
`

const VisuallyHidden = ({ children }) => <Wrapper>{children}</Wrapper>

export default VisuallyHidden
